<template>
  <div>
    <!-- 
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-radio-group
          v-model="testType"
          label="Percentual de erros considerado nos testes:"
          row
        >
          <v-radio
            label="Personalizado"
            value="PERSONALIZADO"
          />
          <v-radio
            label="NVAL"
            value="NVAL"
          />
        </v-radio-group>
      </v-col>
    </v-row> 
    -->
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-data-table
          id="second-step-validacao-bdgd-table"
          v-model="selectedItemLocal"
          :headers="headers"
          :items="itemsTable"
          :search.sync="search"
          item-key="teste"
          :single-select="false"
          show-select
          :loading="loading"
          :hide-default-header="loading"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          no-results-text="Nenhum registro correspondente encontrado"
        >
          <template
            v-slot:top
            v-if="!loading"
          >
            <span id="second-step-search">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Procurar"
                hide-details
                single-line
                class="mx-4 mb-3"
              />
            </span>
          </template>
          <template v-slot:[`item.teste`]="{ item }">
            <span :title="item.descricao">
              {{ item.teste }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-checkbox
          v-model="runSeparately"
          label="Executar cada teste selecionado separadamente?"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    itemsTable: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Selecionar todos os testes',
        value: 'teste'
      }
    ],
    runSeparately: false,
    testType: 'PERSONALIZADO'
  }),
  computed: {
    selectedItemLocal: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit('update:selectedItem', newValue);
      }
    }
  },
  watch: {
    selectedItem(arrNewVal) {
      this.$emit('stepReleased', arrNewVal.length);
    },
    runSeparately(newVal) {
      this.$emit('runSeparately:changed', newVal);
    },
    testType(newVal) {
      this.$emit('testType:changed', newVal);
    }
  }
};
</script>

<style>
.text-tabs-validacao-bdgd {
  color: #333 !important;
  font-size: 17px !important;
  font-weight: 300 !important;
}

.text-tabs-validacao-bdgd span {
  color: #fff !important;
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: 1.4em !important;
  letter-spacing: 0.0073529412em !important;
}
</style>
