<template>
  <v-container fluid>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione o mês e versão que será validada
      </div>
      <first-tab-content
        :itemsTable="itemsTableFirstTab"
        :selectedItem.sync="selectedItemFirstTab"
        :loading.sync="loadingFirstTab"
        @stepReleased="$emit('step-observer', { step: 0, complete: $event })"
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione as entidades que serão validadas
      </div>
      <second-tab-content
        :itemsTable="itemsTableSecondTab"
        :selectedItem.sync="selectedItemSecondTab"
        :loading.sync="loadingSecondTab"
        @stepReleased="$emit('step-observer', { step: 1, complete: $event })"
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione os testes que serão executados na validação
      </div>
      <third-tab-content
        :itemsTable="itemsTableThirdTab"
        :selectedItem.sync="selectedItemThirdTab"
        :loading.sync="loadingThirdTab"
        @stepReleased="$emit('step-observer', { step: 2, complete: $event })"
        @runSeparately:changed="handleRunSeparatelyChanged"
        @testType:changed="handleTestTypeChanged"
      />
    </v-tab-item>
  </v-container>
</template>

<script>
import ValidacoesBdgdsService from '@/services/ValidacoesBdgdsService';
import FirstTabContent from '@/components/geracao-bdgd/validacoes-bdgd/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/geracao-bdgd/validacoes-bdgd/tabs/SecondTabContent.vue';
import ThirdTabContent from '@/components/geracao-bdgd/validacoes-bdgd/tabs/ThirdTabContent.vue';

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FirstTabContent,
    SecondTabContent,
    ThirdTabContent
  },
  created() {
    this.fillFirstTab();
    this.fillThirdTab();
  },
  data() {
    return {
      loadingFirstTab: false,
      loadingSecondTab: false,
      loadingThirdTab: false,
      itemsTableFirstTab: [],
      itemsTableSecondTab: [],
      itemsTableThirdTab: [],
      selectedItemFirstTab: [],
      selectedItemSecondTab: [],
      selectedItemThirdTab: [],
      totalRecordsThirdTab: 0,
      runSeparately: false,
      testType: 'PERSONALIZADO'
    };
  },
  computed: {
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    fillFirstTab() {
      this.loadingFirstTab = true;
      ValidacoesBdgdsService.getDatesAndRegistersImportedEntities()
        .then((response) => {
          this.itemsTableFirstTab = response.data;
          const selectedBdgdVersion = this.itemsTableFirstTab.filter(
            (item) =>
              item.bdgd_versao_id === this.userSelectedCompanyBdgdVersion.id
          );
          this.selectedItemFirstTab = selectedBdgdVersion;
        })
        .finally(() => (this.loadingFirstTab = false));
    },
    fillSecondTab(selectedDate, companyId, bdgdVersionId) {
      this.loadingSecondTab = true;
      ValidacoesBdgdsService.getImportedEntitiesAndRegistersByDate(
        selectedDate,
        companyId,
        bdgdVersionId
      )
        .then((response) => (this.itemsTableSecondTab = response.data))
        .catch(() =>
          this.$emit('stepReleased', { numberStep: 1, available: false })
        )
        .finally(() => (this.loadingSecondTab = false));
    },
    fillThirdTab() {
      this.loadingThirdTab = true;
      ValidacoesBdgdsService.getTestesAtivos()
        .then((response) => (this.itemsTableThirdTab = response.data))
        .finally(() => (this.loadingThirdTab = false));
    },
    handleRunSeparatelyChanged(newVal) {
      this.runSeparately = newVal;
    },
    handleTestTypeChanged(newVal) {
      this.testType = newVal;
    },
    exportData() {
      let arrEntidades = [];
      this.selectedItemSecondTab.forEach((item) => {
        arrEntidades.push(item.entidade);
      });
      let arrTestes = [];
      this.selectedItemThirdTab.forEach((item) => {
        arrTestes.push(item.id);
      });
      return {
        entidades: JSON.stringify(arrEntidades),
        testes: JSON.stringify(arrTestes),
        numero_linhas: this.totalRecordsThirdTab,
        bdgd_versao_id: this.selectedItemFirstTab[0].bdgd_versao_id,
        rodar_testes_separadamente: this.runSeparately,
        tipo_teste: this.testType
      };
    }
  },
  watch: {
    selectedItemFirstTab(arrNewVal) {
      let selectedDate = arrNewVal.length
        ? arrNewVal[0].data_registro
        : undefined;
      let companyId = this.$store.getters.getCompanyId;
      let bdgdVersionId = arrNewVal.length
        ? arrNewVal[0].bdgd_versao_id
        : undefined;
      if (selectedDate && companyId && bdgdVersionId) {
        this.fillSecondTab(selectedDate, companyId, bdgdVersionId);
      }
    },
    selectedItemSecondTab(arrNewVal) {
      let totalRecords = 0;
      if (arrNewVal.length) {
        totalRecords = arrNewVal
          .map((item) => item.registros)
          .reduce((prev, next) => prev + next);
      }
      this.totalRecordsThirdTab = totalRecords;
    }
  }
};
</script>
